import React from 'react'

import { Box, Container, Flex } from '@components/Grid'
import { SectionTitle, Text } from '@components/Text'

const Content = () => (
  <Box as="section" bg="primary.100" py={6} px={4} id="services">
    <Container>
      <SectionTitle color="primary.900">POLÍTICA DE PRIVACIDAD</SectionTitle>
      <Box bg="white" position="relative" px={6} py={6} zIndex="2" width="100%">
        <Text variant="body">
          Siguiendo los principios de licitud, lealtad y transparencia del
          Reglamento (UE) 2016/679 General de Protección de Datos (“RGPD”) y la
          Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
          Personales y Garantía de los Derechos Digitales (“LOPDGDD”), ponemos a
          su disposición la presente Política de Privacidad para explicarle el
          tratamiento de datos de carácter personal que realizamos a través de
          la página web{' '}
          <a href="https://aibrok.com.mx">https://aibrok.com.mx</a>.
        </Text>
        <Text as="h3" color="primary.900">
          1. ¿Quién es el Responsable del tratamiento de sus datos?
        </Text>
        <Text variant="body">
          Responsable: Aibrok Consulting Correduria de Seguros, S.L. (en
          adelante, “Aibrok”)
          <br />
          NIF: <b>B42722082</b>
          <br />
          Domicilio Social: Calle Dunas de San Antón, 5, Chiclana de la Frontera
          (Cádiz), C.P. 11130, España
          <br />
          Email: <b>info@aibrok.com.mx</b>
          <br />
          Teléfono: <b>+34 955 44 19 90 </b>
          <br />
          Email protección de datos: <b>info@aibrok.com.mx</b>
        </Text>
        <Text as="h3" color="primary.900">
          2. ¿Con qué finalidad tratamos sus datos personales?
        </Text>
        <Text variant="body">
          A través de la presente página web, únicamente tratamos la información
          que nos facilita con las siguientes finalidades del tratamiento:
          <br />
          <br />
          FINALIDADES DEL TRATAMIENTO
          <br />
          <br />
          <ol>
            <li>
              Gestionar su candidatura como potencial trabajador o colaborador a
              través del formulario de “Contacto” / Enviarle comunicaciones
              comerciales sobre productos o servicios de proveedores de Aibrok.
              A estos efectos. trataremos: (i) Nombre, (ii) Apellidos, (iii)
              E-mail, (iv) Teléfono, (v) Formación y desarrollo profesional
              [Mensaje, experiencia, posición deseada, Currículum Vitae.
              <br />
              <br />
              *En caso de facilitar datos de terceros, el usuario se compromete
              a obtener previamente el consentimiento expreso del interesado.
            </li>
            <li>
              Gestionar el análisis de sus datos de navegación a través de la
              instalación de las cookies. Puede consultar todos los detalles en
              nuestra Política de Cookies.
            </li>
          </ol>
        </Text>
        <Text as="h3" color="primary.900">
          3. ¿Cuál es la legitimación para el tratamiento de sus datos?
        </Text>
        <Text variant="body">
          Las bases de legitimación para el tratamiento de sus datos de acuerdo
          a las finalidades identificadas son:
          <br />
          <br />
          BASE LEGAL
          <br />
          <br />
          <ol>
            <li>
              Art. 6.1.a) RGPD: consentimiento del propio interesado / Art.
              6.1.a) RGPD: consentimiento del propio interesado.
            </li>
            <li>Art. 6.1.a) RGPD: consentimiento del propio interesado.</li>
          </ol>
          Los datos que le solicitamos son adecuados, pertinentes y
          estrictamente necesarios y en ningún caso está obligado a
          facilitárnoslos, pero su no comunicación podrá afectar a la finalidad
          del tratamiento o a la imposibilidad de gestionar sus intereses.
        </Text>
        <Text as="h3" color="primary.900">
          4. ¿Por cuánto tiempo conservaremos sus datos personales?
        </Text>
        <Text variant="body">
          <ol>
            <li>
              Los datos los conservaremos durante el plazo máximo de un año,
              caso que no llegue a formar parte del equipo de Aibrok como
              trabajador o colaborador.
            </li>
            <li>
              Durante la caducidad de las cookies, de conformidad con la
              Política de Cookies.
            </li>
          </ol>
        </Text>
        <Text as="h3" color="primary.900">
          5. ¿A qué destinatarios se comunicarán sus datos?
        </Text>
        <Text variant="body">
          Aibrok no comunicará sus datos a ningún tercero, salvo que preste su
          consentimiento expreso o en cumplimiento de una obligación legal.
        </Text>
        <Text as="h3" color="primary.900">
          6. ¿Cuáles son sus derechos cuando nos facilita sus datos?
        </Text>
        <Text variant="body">
          Los derechos de protección de datos de los que son titulares los
          interesados son:
          <br /> <br />
          Derecho de acceso: obtener información sobre si sus datos están siendo
          objeto de tratamiento, la finalidad del tratamiento, el origen de los
          datos y cesiones realizadas o previstas.
          <br /> <br />
          Derecho de rectificación: pedir que se modifiquen aquellos de sus
          datos que resulten ser inexactos o incompletos.
          <br /> <br />
          Derecho de supresión: pedir que se eliminen o borren los datos
          personales cuando ya no sean necesarios, cuando se retire el
          consentimiento, hayan sido tratados ilícitamente, etc.
          <br /> <br />
          En ese caso, se deberán poder bloquear los datos y, posteriormente,
          eliminar cualquier copia, enlace y réplica de los datos. Todo ello,
          siempre que no lo impida una obligación legal, o sea necesario para el
          ejercicio o defensa de reclamaciones.
          <br /> <br />
          Derecho de oposición: solicitar que no se lleve a cabo un determinado
          tratamiento de datos de carácter personal.
          <br /> <br />
          Derecho a solicitar la limitación de su tratamiento: solicitar que no
          se apliquen sus datos personales a las operaciones de tratamiento que
          en cada caso corresponderían.
          <br /> <br />
          Derecho a la portabilidad de los datos: pedir que se faciliten sus
          datos a otra entidad, o a sí mismo, en un formato estructurado, de uso
          común y lectura mecánica. Dicha solicitud sólo se podrá realizar sobre
          datos cuyo tratamiento esté basado en el consentimiento o en un
          contrato, y siempre que el tratamiento se efectúe por medios
          automatizados.
          <br /> <br />
          Derecho a no ser objeto de decisiones automatizadas: solicitar no ser
          objeto de una decisión basada únicamente en el tratamiento
          automatizado, incluida la elaboración de perfiles, que produzca
          efectos jurídicos o le afecte significativamente de modo similar (en
          principio, no se aplicará a los tratamientos de Aibrok).
          <br /> <br />
          Los titulares de los datos personales obtenidos podrán ejercer sus
          derechos dirigiendo una comunicación por escrito al domicilio social
          de Aibrok o al correo electrónico habilitado a tal efecto,
          info@aibrok.com.mx
          <br /> <br />
          Modelos, formularios y más información disponible sobre sus derechos
          en la página web de la autoridad de control nacional, Agencia Española
          de Protección de Datos, en adelante, AEPD, www.aepd.es.
        </Text>
        <Text as="h3" color="primary.900">
          7. ¿Puedo retirar el consentimiento?
        </Text>
        <Text variant="body">
          Usted tiene la posibilidad y el derecho a retirar el consentimiento
          para cualquier finalidad específica otorgada en su momento, sin que
          ello afecte a la licitud del tratamiento basado en el consentimiento
          previo a su retirada.
        </Text>
        <Text as="h3" color="primary.900">
          8. ¿Dónde puedo reclamar en caso de que considere que no se tratan mis
          datos correctamente?
        </Text>
        <Text variant="body">
          Si algún interesado considera que sus datos no son tratados
          correctamente por Aibrok puede dirigir sus reclamaciones al Delegado
          de Protección de Datos (info@aibrok.com.mx) o a la autoridad de
          protección de datos que corresponda, siendo la AEPD la indicada en el
          territorio nacional, www.aepd.es.
        </Text>
        <Text as="h3" color="primary.900">
          9. Seguridad y actualización de sus datos personales
        </Text>
        <Text variant="body">
          Con el objetivo de salvaguardar la seguridad de sus datos personales,
          le informamos que Aibrok ha adoptado todas las medidas de índole
          técnica y organizativa necesarias para garantizar la seguridad de los
          datos personales suministrados. Todo ello para evitar su alteración,
          pérdida, y/o tratamientos o accesos no autorizados, tal como exige la
          normativa, si bien la seguridad absoluta no existe.
          <br /> <br />
          Es importante que, para que podamos mantener sus datos personales
          actualizados, nos informe siempre que se produzca una modificación de
          los mismos.
        </Text>
        <Text as="h3" color="primary.900">
          10. Confidencialidad
        </Text>
        <Text variant="body">
          Aibrok le informa que sus datos serán tratados con el máximo celo y
          confidencialidad por todo el personal que intervenga en cualquiera de
          las fases del tratamiento. Le recordamos que no cederemos ni
          comunicaremos a ningún tercero sus datos, excepto en los casos
          legalmente previstos, o salvo que el interesado nos hubiera autorizado
          expresamente.
        </Text>
      </Box>
    </Container>
  </Box>
)

export default Content
