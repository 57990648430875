import React from 'react'
import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { Content } from '../screens/PrivacyPolicies'

const PrivacyPage = () => {
  return (

    <Layout>
      <SEO title="Privacy Policies" />
      <Content />
    </Layout>

  )
}

export default PrivacyPage
